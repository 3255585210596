import { Component } from '@angular/core';
import { UpdateService } from './services/configuration/update.service';
import { RouterOutlet } from '@angular/router';
import { ToolbarComponent } from './nav/toolbar/toolbar.component';
import { IconRegistrationService } from './services/configuration/icon-registration.service';
import { CurrentUserService } from './services/current-user.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FooterComponent } from './nav/footer/footer.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [RouterOutlet, ToolbarComponent, NgxSpinnerModule, FooterComponent],
})
export class AppComponent {
	constructor(
		private updateService: UpdateService,
		private iconRegistrationService: IconRegistrationService,
		private currentUserService: CurrentUserService,
	) {
		iconRegistrationService.setupMaterialIcons();
	}
}
