import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../environments/environment';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { AppUser, CurrentUserService } from '../../services/current-user.service';
import { MatDividerModule } from '@angular/material/divider';

@Component({
	selector: 'app-toolbar',
	standalone: true,
	imports: [
		CommonModule,
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		RouterLink,
		MatDividerModule,
	],
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
	protected readonly environment = environment;
	protected currentPath: string = '/';
	protected currentUser: AppUser | undefined | null;
	protected isLoading = true;

	constructor(
		public currentUserService: CurrentUserService,
		private readonly route: Router,
	) {}

	ngOnInit(): void {
		this.currentUserService.getUser().subscribe(user => {
			if (user) {
				this.currentUser = user;
			} else {
				this.currentUser = user;
			}
			this.isLoading = false;
		});

		this.route.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.currentPath = event.url;
			}
		});
	}
}
