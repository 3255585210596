import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { environment } from '../../environments/environment';

export const customTitleResolver: ResolveFn<string> = (route: ActivatedRouteSnapshot) => {
	const hostName = environment.baseHostName;
	const title = route.data['title'];
	if (title) {
		return `${title} | ${hostName}`;
	}
	return `${hostName}`;
};
