<div>
	<mat-toolbar color="primary">
		<mat-toolbar-row class="min-h-16">
			<button mat-icon-button aria-label="Back" routerLink="" *ngIf="currentPath !== '/'" class="mr-4">
				<mat-icon>arrow_back</mat-icon>
			</button>
			<span [ngClass]="currentPath === '/'? 'ml-11': ''" data-testid="topHeader">{{ environment.baseHostName }}</span>
			<span class="flex-auto"></span>
			<div *ngIf="!isLoading && !(currentPath === '/login' || currentPath === '/sign-up')  && currentUser === null">
				<a mat-raised-button routerLink="login" class="tertiary-button">
					<mat-icon>login</mat-icon>
					<span aria-label="Login Button">Login/Sign-up</span></a>
			</div>
			<div *ngIf="currentUser">
				<button mat-icon-button [matMenuTriggerFor]="navMenu" aria-label="Navigation Menu">
					<mat-icon>menu</mat-icon>
				</button>
				<mat-menu #navMenu="matMenu">
					<button mat-menu-item routerLink="profile" aria-label="User Profile"
									*ngIf="currentUser">
						<mat-icon>manage_accounts</mat-icon>
						<span>{{ currentUser.username }}</span>
					</button>
					<mat-divider *ngIf="currentUser"></mat-divider>
					<button mat-menu-item routerLink="" *ngIf="currentPath !== '/'">
						<mat-icon>home</mat-icon>
						<span aria-label="Home Button">Home</span>
					</button>
					<button mat-menu-item routerLink="complete-signup"
									*ngIf="currentPath !== '/complete-signup' && !currentUserService.isLoggedIn() && currentUserService.needsToCompleteSignup()">
						<mat-icon>how_to_reg</mat-icon>
						<span aria-label="Complete Signup Button">Complete Signup</span>
					</button>

					<button mat-menu-item (click)="currentUserService.logout()"
									*ngIf="currentUserService.isLoggedIn() || currentUserService.needsToCompleteSignup()">
						<mat-icon>logout</mat-icon>
						<span>Logout</span>
					</button>
				</mat-menu>
			</div>
		</mat-toolbar-row>
	</mat-toolbar>
</div>
