import { Routes } from '@angular/router';
import { customTitleResolver } from './resolvers/custom-title.resolver';
import {
	isLoggedInGuard,
	isNotLoggedInGuard,
	needsToCompleteSignupGuard,
	needsToConfirmEmailGuard,
} from './services/current-user.service';

export const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./landing/landing.component').then(mod => mod.LandingComponent),
		pathMatch: 'full',
		title: customTitleResolver,
		data: {
			loc: '/',
			lastmod: '2023-09-11',
			changefreq: 'daily',
			priority: '1.0',
		},
	},
	{
		path: 'privacy-policy',
		loadComponent: () =>
			import('./legal/privacy-policy/privacy-policy.component').then(mod => mod.PrivacyPolicyComponent),
		pathMatch: 'full',
		title: customTitleResolver,
		data: {
			loc: '/',
			lastmod: '2023-09-12',
			changefreq: 'daily',
			priority: '1.0',
		},
	},
	{
		path: 'terms-of-service',
		loadComponent: () =>
			import('./legal/terms-of-service/terms-of-service.component').then(mod => mod.TermsOfServiceComponent),
		pathMatch: 'full',
		title: customTitleResolver,
		data: {
			loc: '/',
			lastmod: '2023-09-12',
			changefreq: 'daily',
			priority: '1.0',
		},
	},
	{
		path: 'login',
		loadComponent: () => import('./auth/login/login.component').then(mod => mod.LoginComponent),
		canActivate: [isNotLoggedInGuard],
		title: customTitleResolver,
		data: {
			loc: '/login',
			lastmod: '2023-09-11',
			changefreq: 'daily',
			priority: '1.0',
			title: 'Login',
		},
	},
	{
		path: 'sign-up',
		loadComponent: () => import('./auth/sign-up/sign-up.component').then(mod => mod.SignUpComponent),
		canActivate: [isNotLoggedInGuard],
		title: customTitleResolver,
		data: {
			loc: '/sign-up',
			lastmod: '2024-09-11',
			changefreq: 'daily',
			priority: '1.0',
			title: 'Sign-up',
		},
	},
	{
		path: 'confirm-email',
		loadComponent: () => import('./auth/confirm-email/confirm-email.component').then(mod => mod.ConfirmEmailComponent),
		canActivate: [needsToConfirmEmailGuard],
		title: customTitleResolver,
		data: {
			loc: '/confirm-email',
			lastmod: '2024-11-11',
			changefreq: 'daily',
			priority: '1.0',
		},
	},
	{
		path: 'complete-signup',
		loadComponent: () =>
			import('./auth/complete-signup/complete-signup.component').then(mod => mod.CompleteSignupComponent),
		canActivate: [needsToCompleteSignupGuard],
		title: customTitleResolver,
		data: {
			loc: '/complete-signup',
			lastmod: '2023-09-11',
			changefreq: 'daily',
			priority: '1.0',
		},
	},
	{
		path: 'forgot-password',
		loadComponent: () =>
			import('./auth/forgot-password/forgot-password.component').then(mod => mod.ForgotPasswordComponent),
		title: customTitleResolver,
		data: {
			loc: '/forgot-password',
			lastmod: '2023-09-11',
			changefreq: 'daily',
			priority: '1.0',
			title: 'Forgot Password',
		},
		children: [
			{
				path: 'have-code',
				title: customTitleResolver,
				loadComponent: () =>
					import('./auth/forgot-password/forgot-password.component').then(mod => mod.ForgotPasswordComponent),
				data: {
					loc: '/forgot-password/have-code',
					lastmod: '2023-09-11',
					changefreq: 'daily',
					priority: '1.0',
					title: 'Have Code?',
				},
			},
		],
	},
	{
		path: 'oauth/sign-in',
		loadComponent: () => import('./auth/oauth/sign-in/sign-in.component').then(mod => mod.SignInComponent),
		title: customTitleResolver,
	},
	{
		path: 'profile',
		loadComponent: () => import('./user/profile/profile.component').then(mod => mod.ProfileComponent),
		canActivate: [isLoggedInGuard],
		title: customTitleResolver,
		data: {
			loc: '/profile',
			lastmod: '2023-09-11',
			changefreq: 'daily',
			priority: '1.0',
			title: 'Profile',
		},
	},
];
