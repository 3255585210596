import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UpdateService {
	constructor(
		private readonly swUpdate: SwUpdate,
		private readonly ngZone: NgZone,
	) {
		this.ngZone.runOutsideAngular(() =>
			interval(1000 * 60).subscribe(() => {
				if (swUpdate.isEnabled) {
					swUpdate.checkForUpdate().then(hadUpdate => {
						if (hadUpdate) {
							console.log('SW Update available, reloading app');
							document.location.reload();
						}
					});
				} else {
					console.log('SW Update not enabled');
				}
			}),
		);
	}
}
