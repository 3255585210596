import { inject, Injectable } from '@angular/core';
import {
	CognitoAccessToken,
	CognitoUser,
	CognitoUserAttribute,
	CognitoUserPool,
	CognitoUserSession,
	CookieStorage,
	ISignUpResult,
} from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import { CanActivateFn, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedLoginCredentialsService } from '../auth/services/shared-login-credentials.service';

export interface AppUser {
	changeUsername?: boolean;
	email?: string;
	username?: string;
	additionalProperties?: AdditionalProperties;
}

interface AdditionalProperties {
	[k: string]: string;
}

@Injectable({
	providedIn: 'root',
})
export class CurrentUserService {
	private readonly userPool: CognitoUserPool;
	private cognitoUser: CognitoUser | null;
	private appUser: AppUser | undefined;
	private readonly appUserSub: BehaviorSubject<AppUser | undefined | null> = new BehaviorSubject<
		AppUser | undefined | null
	>(undefined);
	private cognitoUserAccessToken: CognitoAccessToken | undefined;
	private readonly isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(
		private readonly router: Router,
		private readonly spinnerService: NgxSpinnerService,
	) {
		this.userPool = new CognitoUserPool({
			UserPoolId: environment.cognitoUserpoolId,
			ClientId: environment.cognitoWebclientId,
			Storage: new CookieStorage({ domain: `.${environment.baseHostName}`, secure: true, sameSite: 'strict' }),
		});

		const currentUser = this.userPool.getCurrentUser();
		if (currentUser) {
			this.cognitoUser = currentUser;
			this.setCognitoUser(currentUser);
		} else {
			this.cognitoUser = null;
			this.appUserSub.next(null);
			this.isLoaded$.next(true);
		}
	}

	isLoggedIn() {
		return this.appUser !== undefined;
	}

	needsToCompleteSignup() {
		return this.appUser === undefined && this.cognitoUser !== null;
	}

	getUserPool() {
		return this.userPool;
	}

	getUser(): BehaviorSubject<AppUser | undefined | null> {
		return this.appUserSub;
	}
	getToken() {
		return this.cognitoUserAccessToken;
	}

	setCognitoUser(cognitoUser: CognitoUser | null) {
		this.cognitoUser = cognitoUser;
		if (this.cognitoUser) {
			this.cognitoUser.getSession((error: Error, session: CognitoUserSession | null) => {
				if (error || session === null) {
					this.appUser = undefined;
					this.cognitoUser?.signOut();
					this.cognitoUser = null;
					this.appUserSub.next(undefined);
					this.isLoaded$.next(true);
					return;
				}
				this.cognitoUserAccessToken = session.getAccessToken();
				this.cognitoUser?.getUserAttributes((err, result) => {
					if (err && err.name === 'UserNotFoundException') {
						this.appUser = undefined;
						this.cognitoUser?.signOut();
						this.cognitoUser = null;
						this.appUserSub.next(undefined);
						this.isLoaded$.next(true);
						return;
					}
					if (result && result.length > 0) {
						this.appUser = { additionalProperties: {} };
						for (const element of result) {
							if (element.getName() === 'preferred_username') {
								this.appUser.username = element.getValue();
							} else if (element.getName() === 'email') {
								this.appUser.email = element.getValue();
							} else if (element.getName() === 'custom:change_pref_username') {
								this.appUser.changeUsername = element.getValue() === 'True';
							} else {
								this.appUser.additionalProperties![`${element.getName()}`] = element.getValue();
							}
						}
						if (!this.appUser.username) {
							this.appUser = undefined;
							this.router.navigate(['complete-signup']).then();
							this.isLoaded$.next(true);
						} else {
							this.appUserSub.next(this.appUser);
							this.isLoaded$.next(true);
						}
					}
				});
			});
		}
	}

	logout(providerLoginAgain?: string) {
		this.userPool.getCurrentUser()?.signOut();

		if (providerLoginAgain) {
			localStorage.setItem('providerLoginAgain', providerLoginAgain);
		}

		window.location.href = `${environment.cognitoDomain}/logout
		?client_id=${environment.cognitoWebclientId}
		&logout_uri=${location.protocol + '//' + location.host}/login`;
	}

	loginWithGoogle() {
		window.location.href = `${environment.cognitoDomain}/oauth2/authorize
		?identity_provider=Google
		&redirect_uri=${location.protocol + '//' + location.host}/oauth/sign-in
		&response_type=CODE
		&client_id=${environment.cognitoWebclientId}
		&scope=aws.cognito.signin.user.admin email openid phone profile`;
	}

	signup(username: string, email: string, password: string) {
		return new Observable<ISignUpResult | undefined>(obs => {
			this.spinnerService.show().then();

			this.userPool.signUp(
				username,
				password,
				[
					new CognitoUserAttribute({
						Name: 'email',
						Value: email,
					}),
					new CognitoUserAttribute({
						Name: 'custom:signup_pref_username',
						Value: username,
					}),
				],
				[],
				(err, result) => {
					this.spinnerService.hide().then();
					if (err) {
						obs.error(err);
						return;
					}
					obs.next(result);
					obs.complete();
				},
			);
		});
	}

	isLoaded() {
		return this.isLoaded$;
	}
}

export const isLoggedInGuard: CanActivateFn = () => {
	const isLoggedIn = inject(CurrentUserService).isLoggedIn();

	if (!isLoggedIn) {
		inject(Router).navigate(['']).then();
	}

	return isLoggedIn;
};

export const isNotLoggedInGuard: CanActivateFn = () => {
	const isLoggedIn = inject(CurrentUserService).isLoggedIn();

	if (isLoggedIn) {
		inject(Router).navigate(['']).then();
	}

	return !isLoggedIn;
};

export const needsToConfirmEmailGuard: CanActivateFn = () => {
	const needsToConfirmEmail = inject(SharedLoginCredentialsService).loginCredentials$.value?.needsToConfirmEmail;
	if (!needsToConfirmEmail) {
		inject(Router).navigate(['']).then();
	}

	return needsToConfirmEmail;
};

export const needsToCompleteSignupGuard: CanActivateFn = () => {
	const needsToCompleteSignup = inject(CurrentUserService).needsToCompleteSignup();
	if (!needsToCompleteSignup) {
		inject(Router).navigate(['']).then();
	}

	return needsToCompleteSignup;
};
