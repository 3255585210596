import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { CUSTOM_ICON_CODEPOINTS } from '../../../assets/custom-icons/custom-icons';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class IconRegistrationService {
	constructor(
		private matIconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer,
	) {}

	public setupMaterialIcons() {
		this.registerSVGs();
		this.matIconRegistry.registerFontClassAlias('ci', 'icon');
	}

	private registerSVGs() {
		for (const entry in CUSTOM_ICON_CODEPOINTS) {
			this.matIconRegistry.addSvgIcon(entry, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/svg/${entry}.svg`));
		}
	}
}
