import { AppComponent } from './app/app.component';
import { importProvidersFrom, inject, isDevMode, provideAppInitializer } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './app/interceptors/auth.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrentUserService } from './app/services/current-user.service';
import { takeWhile } from 'rxjs';

bootstrapApplication(AppComponent, {
	providers: [
		provideAppInitializer(() => {
			const currentUserService = inject(CurrentUserService);
			return currentUserService.isLoaded().pipe(
				takeWhile(function (loaded) {
					return !loaded;
				}),
			);
		}),
		importProvidersFrom(
			BrowserModule,
			AppRoutingModule,
			NgxSpinnerModule.forRoot({ type: 'ball-pulse' }),
			ServiceWorkerModule.register('ngsw-worker.js', {
				enabled: !isDevMode(),
				registrationStrategy: 'registerWhenStable:30000',
			}),
		),
		provideAnimations(),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
}).catch(err => console.error(err));
