<div class="h-screen bg-white text-black dark:text-white dark:bg-black">
	<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-pulse"
							 [fullScreen]="true"></ngx-spinner>

	<header>
		<app-toolbar></app-toolbar>
	</header>
	<main class="pt-5">
		<div class="container pt-4 text-center">
			<router-outlet></router-outlet>
		</div>
	</main>
	<footer>
		<app-footer></app-footer>
	</footer>
</div>
