import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LoginCredentials {
	needsToConfirmEmail: boolean;
	username: string;
	password: string;
}

@Injectable({
	providedIn: 'root',
})
export class SharedLoginCredentialsService {
	private readonly autoTimeout = 1000 * 60 * 5;

	private readonly _defaultState: LoginCredentials = {
		needsToConfirmEmail: false,
		username: '',
		password: '',
	};
	private readonly loginCredentials: BehaviorSubject<LoginCredentials> = new BehaviorSubject<LoginCredentials>(
		this._defaultState,
	);

	constructor() {}

	setLoginCredentials(username: string, password: string) {
		this.loginCredentials.next({ username, password, needsToConfirmEmail: true });
		setTimeout(() => {
			this.clearLoginCredentials();
		}, this.autoTimeout);
	}

	clearLoginCredentials() {
		this.loginCredentials.next(this._defaultState);
	}

	get loginCredentials$(): BehaviorSubject<LoginCredentials> {
		return this.loginCredentials;
	}
}
